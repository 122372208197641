import "./style.scss";

import { SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import React, { useState } from "react";

export const ASSIST_TRANSFER_QUICK_CONNECT = "Assist Transfer";

export interface ContactInfo {
  readonly contactId: string;
}

interface TransferToConsultantPanelProps {
  readonly contactInfo: ContactInfo;
}

enum TransferState {
  Idle,
  Transferring,
  Error,
}

export const TransferToConsultantPanel: React.FC<TransferToConsultantPanelProps> = (
  props
) => {
  // Local State
  const [expanded, setExpanded] = useState<boolean>(true); // the component is expanded on initialization
  const [errorMessage, setErrorMessage] = useState<string>();
  const [transferState, setTransferState] = useState<TransferState>(
    TransferState.Idle
  );

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  /**
   * Method to invoke direct to agent transfer
   * @param contactId Contact which needs to be transferred
   * @param quickConnectName The name of quick connect which is being used to transfer the contact
   * @param failureCallback Callback function to invoke when the transfer fails
   */
  const transferContact = (
    contactId: string,
    quickConnectName: string,
    failureCallback: connect.SuccessFailCallback
  ) => {
    // Update state to transferring. This disables the transfer button
    setTransferState(TransferState.Transferring);
    // clear any old error message before starting transfer
    setErrorMessage(undefined);

    // fetch the contact
    let contact: connect.Contact | undefined;
    connect.agent((agent) => {
      const allContacts = agent.getContacts();
      contact = allContacts.find(
        (aContact) => aContact.contactId === contactId
      );
    });

    // the contact must be defined and in the CONNECTED state to continue the transfer
    if (!contact) {
      console.error(`Contact associated with contactId ${contactId} not found`);
      failureCallback();
      return;
    } else if (contact.getState().type !== connect.ContactStateType.CONNECTED) {
      console.error(
        `Contact associated with contactId ${contactId} must in ${connect.ContactStateType.CONNECTED} state.`
      );
      failureCallback();
      return;
    }

    // If there is an ongoing voice consultation, simply remove the
    // current agent from the contact to complete the transfer.
    // This is applicable for voice consultations
    if (
      contact?.getType() === connect.ContactType.VOICE &&
      contact.getConnections().length > 2
    ) {
      try {
        contact.getAgentConnection().destroy();
      } catch (e) {
        console.error(`Failed to remove the current agent from the contact`);
        failureCallback();
      }
      return;
    }

    connect.agent((agent) => {
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: function (data) {
          // fetch the correct quick connect endpoint
          const endpoint = data.endpoints.find(
            (aEndpoint) => aEndpoint.name === quickConnectName
          );
          // in case the endpoint for the quick connect is not found
          if (!endpoint) {
            console.error(`Quick connect: ${quickConnectName} not found.`);
            failureCallback();
          } else {
            // initiate the transfer on the contact
            try {
              contact?.addConnection(endpoint);
              // if the contact is voice then the current agent
              // must be removed from the current contact
              if (contact?.getType() === connect.ContactType.VOICE) {
                contact?.onRefresh((refreshContact) => {
                  const thirdPartyConnections = refreshContact?.getThirdPartyConnections();
                  if (
                    thirdPartyConnections &&
                    thirdPartyConnections?.length > 0
                  ) {
                    const connection = thirdPartyConnections[0];
                    // only remove the current agent from the contact if the third party contact
                    // is connected. This means that the contact is at least assigned to a queue
                    // waiting to be accepted by an agent. Disconnecting the current agent before
                    // that will cause the customer contact to be dropped.
                    if (
                      connection.getState().type ===
                      connect.ConnectionStateType.CONNECTED
                    ) {
                      refreshContact?.getAgentConnection().destroy();
                    }
                  }
                });
              }
            } catch (e) {
              failureCallback();
              console.error(
                `Failed to transfer the contact with contactId ${contactId}.`
              );
            }
          }
        },
        failure: () => {
          failureCallback();
          console.error(`Failed to fetch the endpoints.`);
        },
      });
    });
  };

  return (
    <div className={expanded ? "expanded-container" : "collapsed-container"}>
      {
        <div className="title-container" onClick={toggleControllerExpansion}>
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
            />
            <div className="container-title">
              {"Transfer to the consultant"}
            </div>
          </SpaceBetween>
        </div>
      }
      {expanded && (
        <div className="button-container">
          {
            <Button
              onClick={() =>
                void transferContact(
                  props.contactInfo.contactId,
                  ASSIST_TRANSFER_QUICK_CONNECT,
                  () => {
                    setTransferState(TransferState.Error);
                    setErrorMessage(
                      "Failed to transfer to consultation. Please try again."
                    );
                  }
                )
              }
              className="awsui-visual-refresh awsui-polaris-dark-mode transfer-btn"
              disabled={TransferState.Transferring === transferState}
              loading={TransferState.Transferring === transferState}
            >
              Transfer
            </Button>
          }
        </div>
      )}
      {errorMessage && expanded && (
        <div className="message-container">
          <StatusIndicator type="error"></StatusIndicator>
          <div className="error-message">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
