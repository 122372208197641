import "./style.scss";

import { default as Button } from "@amzn/awsui-components-react/polaris/button";
import { default as Select } from "@amzn/awsui-components-react/polaris/select";
import React, { forwardRef, useEffect, useMemo, useState } from "react";

import { getBaseWindowOrigin, isWindowedCcp } from "../../configuration";
import { useSelector } from "../../state/hooks";
import { AssistSurveyPanel } from "../AssistSurvey/index";
import { CcpLoader } from "../CcpLoader";
import { enableChatLiveliness } from "../config";
import { ExternalSessionControl } from "../ExternalSessionControl";
import { useInstanceSelector } from "../hooks/instance-selector";
import { IconOmnia } from "../IconOmnia";
import { IconPolaris } from "../IconPolaris";
import PlaceholderCcp from "../PlaceholderCcp";
import { TransferSurvey } from "../TransferSurvey";
import { TransferToConsultant } from "../TransferToConsultant";
import { BaseFC } from "../types";

export interface CcpContainer extends BaseFC {
  readonly onPopClick: () => void;
  readonly onCloseClick: () => void;
}

/**
 * A Container for the CCP which is to be used both in popped out and embedded applications.
 * It receives 2 props: "onPopClick", "onCloseClick"
 * "onPopClick" and "onCloseClick" are handlers that are called when the "Popout/in" button is clickor the "close" button
 */
export const CcpContainer = forwardRef<HTMLDivElement, CcpContainer>(
  ({ onPopClick, onCloseClick, style }, ref) => {
    // Custom Hooks
    const {
      selectedInstanceOption,
      setSelectedInstanceOption,
      options,
      selectedInstance,
      setSelectedInstance,
      instances,
    } = useInstanceSelector();

    // Local State
    const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);

    // Shared State
    const loadingOrError = useSelector((state) => state.ccpLoading);
    const isCcpPoppedOutOfWindow = useSelector((state) => state.ccpInPopup);
    const ccpPopupError = useSelector((state) => state.ccpPopupError);

    // When CCP window is in popup and the selected instance gets updated, send a message to the parent window.
    useEffect(() => {
      const baseWindowOrigin = getBaseWindowOrigin();

      console.debug(
        "[ITSupportConnectAgentClient] Selected instance updated.",
        {
          baseWindowOrigin,
          isWindowedCcp: isWindowedCcp(),
        }
      );
      if (isWindowedCcp() && window.opener && baseWindowOrigin) {
        console.debug(
          "[ITSupportConnectAgentClient] Post a message to parent window:",
          selectedInstance
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        window.opener?.postMessage(
          { type: "SELECTED_INSTANCE_CHANGE", selectedInstance },
          baseWindowOrigin
        );
      }
    }, [selectedInstance]);

    // Local Vars
    const isCurrentInstanceSelected =
      !!selectedInstanceOption &&
      selectedInstance?.ccpUrl === selectedInstanceOption.id;

    /**
     * isHidden must be surfaced here because wrapping the container div
     * inside the CcpLoading widget creates a new html element on each render
     */
    const isHidden =
      loadingOrError.loading || loadingOrError.error || ccpPopupError;

    /**
     * Adds the "(Current)" labelTag to the options list
     */
    const instanceOptionsWithCurrent = useMemo(() => {
      return options.map((x) =>
        x.id === selectedInstance?.ccpUrl ? { ...x, labelTag: "(Current)" } : x
      );
    }, [options, selectedInstance?.ccpUrl]);

    /**
     * Adds the "(Current)" labelTag to the selectedOption
     */
    const selectedInstanceOptionWithCurrent = useMemo(() => {
      return isCurrentInstanceSelected
        ? {
            ...(selectedInstanceOption as Select.Option),
            labelTag: "(Current)",
          }
        : selectedInstanceOption;
    }, [selectedInstanceOption, isCurrentInstanceSelected]);

    // Handlers
    const handleLaunchClick = (): void => {
      if (selectedInstanceOption) {
        const instance = instances.find(
          (i) => i.ccpUrl === selectedInstanceOption.id
        );
        setSelectedInstance(instance);
        setIsOptionsVisible(false);
      }
    };

    const handleDismiss = (): void => {
      setIsOptionsVisible(false);
      if (selectedInstance) {
        setSelectedInstanceOption(
          options.find((op) => selectedInstance.ccpUrl === op.id)
        );
      }
    };

    // Renders
    const renderWarning = (): React.ReactNode => {
      return (
        <div className="ccp-container-options-warning">
          <IconPolaris
            className="ccp-container-options-warning-icon"
            variant="link"
            name="status-info"
          />
          <div className="ccp-container-options-warning-text">
            {
              "All Connect contacts will be closed if you change instances while online"
            }
          </div>
        </div>
      );
    };

    const renderCcpContainerHeaderForCCp = (): JSX.Element => {
      return (
        <div className="ccp-container-header">
          <div className="ccp-container-header-left">
            <IconOmnia
              name="connect"
              variant="alternate"
              className="ccp-container-header-icon"
            />
            <div className="ccp-container-header-text">{"Amazon Connect"}</div>
          </div>
          <div className="ccp-container-header-right">
            <IconOmnia
              name="globe"
              onClick={() =>
                !isOptionsVisible ? setIsOptionsVisible(true) : handleDismiss()
              }
              variant="alternate"
            />
            <IconOmnia
              name={isWindowedCcp() ? "pop-in" : "pop-out"}
              onClick={onPopClick}
              variant="alternate"
            />
            {!isWindowedCcp() && (
              <IconPolaris
                name="close"
                onClick={onCloseClick}
                variant="normal"
              />
            )}
          </div>
        </div>
      );
    };

    const getCcpContainerHeaderForPlaceholderCCp = (): JSX.Element => {
      return (
        <div>
          <div className="ccp-container-icon-cross">
            <IconPolaris
              name="close"
              className="ccp-container-icon-cross-close-icon"
              onClick={onCloseClick}
              variant="normal"
            />
          </div>
        </div>
      );
    };

    return (
      <div className="ccp-container" style={style}>
        {isCcpPoppedOutOfWindow
          ? getCcpContainerHeaderForPlaceholderCCp()
          : renderCcpContainerHeaderForCCp()}
        <div
          className="ccp-container-content"
          style={{
            display: isCcpPoppedOutOfWindow ? "none" : "contents",
          }}
        >
          <div
            className="ccp-container-options"
            style={{ display: isOptionsVisible ? "block" : "none" }}
          >
            {!isCurrentInstanceSelected && renderWarning()}
            <div className="ccp-container-options-primary-text">
              {"Change Connect Instance"}
            </div>
            <div className="ccp-container-options-secondary-text">
              {"Please select an instance to launch"}
            </div>

            <Select
              placeholder={"Select Instance"}
              className={"ccp-container-options-instance-select"}
              options={instanceOptionsWithCurrent}
              selectedOption={selectedInstanceOptionWithCurrent}
              onChange={(e) =>
                setSelectedInstanceOption(e.detail.selectedOption)
              }
              triggerVariant="option"
            />

            <div className="ccp-container-options-buttons">
              <Button variant="normal" onClick={handleDismiss}>
                {"Cancel"}
              </Button>
              <Button
                className="main-connect-instance-launch"
                variant="primary"
                onClick={handleLaunchClick}
              >
                {"Change"}
              </Button>
            </div>
          </div>
          <CcpLoader onRetryClick={handleLaunchClick} />
          <div
            className="ccp-container-iframe"
            ref={ref}
            style={{
              display: isCcpPoppedOutOfWindow || isHidden ? "none" : "block",
            }}
          ></div>
          <TransferSurvey></TransferSurvey>
          <AssistSurveyPanel></AssistSurveyPanel>
          <TransferToConsultant></TransferToConsultant>
          {enableChatLiveliness() && (
            <ExternalSessionControl></ExternalSessionControl>
          )}
        </div>

        {isCcpPoppedOutOfWindow && <PlaceholderCcp />}
      </div>
    );
  }
);

CcpContainer.displayName = "CcpContainer";
