import "amazon-connect-streams";

import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { CONSULTATION_BROADCAST_CHANNEL } from "../../constants";
import {
  addConsultedContact,
  removeConsultedContact,
} from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";
import { AppStateContext } from "../../state/provider";
import { useMounted } from "../hooks/common";
import {
  ContactInfo,
  TransferToConsultantPanel,
} from "./TransferToConsultantPanel";

export const ALLOWED_CHANNELS: connect.ContactType[] = [
  connect.ContactType.VOICE,
  connect.ContactType.CHAT,
];
export const ALLOWED_STATES: connect.ContactStateType[] = [
  connect.ContactStateType.CONNECTED,
];

export const TransferToConsultant: React.FC<unknown> = () => {
  const dispatch = useDispatch();

  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const consultatedContact = useSelector((state) => state.consultedContacts);
  const [checkConsultedContacts, setCheckConsultedContact] = useState<boolean>(
    () => true
  );
  // This is used to keep track of the current contact on the screen
  const refCurrentViewedContact = useRef<string>();

  const appState = useContext(AppStateContext);
  const mounted = useMounted();

  // broadcast channel to share consultation start status
  const consultation_broadcast_channel: MutableRefObject<BroadcastChannel> = useRef(
    new BroadcastChannel(CONSULTATION_BROADCAST_CHANNEL)
  );

  // /**
  //  * This function returns the contact with the specified contactId and allowed states, or undefined when no such a contact exists.
  //  * @param agent the agent object.
  //  * @param contactId the contactId in Amazon Connect.
  //  * @param allowedStates only return the contact if it is in one of these states.
  //  * @returns
  //  */
  const findContact = (
    agent: connect.Agent,
    contactId: string,
    allowedTypes: connect.ContactType[] = ALLOWED_CHANNELS,
    allowedStates: connect.ContactStateType[] = ALLOWED_STATES
  ): connect.Contact | undefined => {
    return agent
      .getContacts()
      .find(
        (contact) =>
          contact.contactId === contactId &&
          allowedTypes.includes(contact.getType()) &&
          allowedStates.includes(contact.getState().type)
      );
  };

  // Hook for setting up and cleaning up the broadcast channel
  useEffect(() => {
    consultation_broadcast_channel.current.addEventListener(
      "message",
      (event) => {
        connect.agent((agent) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const consultedContactId = event.data?.contactId;
          if (consultedContactId) {
            const contact = findContact(agent, consultedContactId);
            if (contact) {
              // if the contact is valid then add it to the list of consulted contacts
              dispatch(addConsultedContact(consultedContactId));
            }
          }
        });
      }
    );

    // remove the broadcast channel when the component is unmounted
    return () => {
      consultation_broadcast_channel.current.close();
    };
  }, []);

  /**
   * Hook checks if the transfer window needs to be displayed
   * when the check window is requested
   */
  useEffect(() => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => {
        if (refCurrentViewedContact.current) {
          // find the current contact and make sure it meets requirements
          const contact = findContact(agent, refCurrentViewedContact.current);
          // if the contact exists and consultation was requested for it
          // then display the transfer window for it
          if (contact && consultatedContact.includes(contact.contactId)) {
            setContactInfo({
              contactId: contact.contactId,
            });
          } else {
            setContactInfo(undefined);
          }
        }
      });
    }
  }, [checkConsultedContacts, consultatedContact]);

  // add the connect event listeners when the ccp is initialized
  useEffect(() => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => addConnectEventListeners(agent));
    }
  }, [appState.ccpInitialized]);

  // add connect event listeners
  const addConnectEventListeners = (agent: connect.Agent): void => {
    /**
     * When a new contact is viewed then update the currentViewContact
     * and check if the transfer window needs to be displayed for the contact
     */
    connect.core.onViewContact((viewedContact) => {
      if (mounted.current) {
        // update the current contact on the screen.
        refCurrentViewedContact.current = viewedContact.contactId;

        // trigger hook to check if currently viewed contact is consulted contact
        setCheckConsultedContact(
          (checkConsultedContacts) => !checkConsultedContacts
        );
      }
    });

    /**
     * When the contact is disconnected then cleanup the consultated contacts
     * And chech if the transfer window needs to displayed for the current contact
     */
    connect.contact((contact) => {
      contact.onACW((acwContact) => {
        dispatch(removeConsultedContact(acwContact.contactId));
      });
    });
  };

  return contactInfo ? (
    <TransferToConsultantPanel
      contactInfo={contactInfo}
    ></TransferToConsultantPanel>
  ) : (
    <></>
  );
};
